import { createSlice } from '@reduxjs/toolkit';

const initialFunsharingrState = {
  funsharingDraft: [],
  funsharingDisplay: [],
  funsharingUserHistory: [],
  funsharingById: undefined,
  funsharingHistoryById: undefined,
  funsharingUserHistoryById: undefined,
  funsharingUser: undefined
};

export const FunsharingSlice = createSlice({
  name: 'funsharing',
  initialState: initialFunsharingrState,
  reducers: {
    // create Funsharing
    createdFunsharing: (state, action) => {
      state.entities.unshift(action.payload);
    },

    // get Funsharing
    fetchFunsharingDraft: (state, action) => {
      state.entities = action.payload;
    },
    fetchFunsharingDisplay: (state, action) => {
      state.funsharingDisplay = action.payload;
    },
    fetchFunsharingUserHistory: (state, action) => {
      state.funsharingUserHistory = action.payload;
    },
    fetchFunsharingUser: (state, action) => {
      state.funsharingUserHistory = action.payload;
    },
    fetchUser: (state, action) => {
      state.funsharingUser = action.payload;
    },

    // get Funsharing by _id
    fetchFunsharingById: (state, action) => {
      state.funsharingById = action.payload;
    },
    fetchFunsharingUserHistoryById: (state, action) => {
      state.funsharingUserHistoryById = action.payload;
    },

    // update Funsharing
    updatedFunsharing: (state, action) => {
      const newState = state.funsharingDraft.map(ent => {
        return ent._id === action?.payload?._id ? action?.payload : ent;
      });
      state.funsharingDraft = newState;
    },
    activedFunsharing: (state, action) => {
      const newState = state.funsharingDisplay.map(ent => {
        return ent._id === action?.payload?._id ? action?.payload : ent;
      });
      state.funsharingDisplay = newState;
    },
    // delete Funsharing
    deletedFunsharing: (state, action) => {
      state.funsharingDraft = state.funsharingDraft.filter(el => !action.payload.includes(el._id));
    }
  }
});
