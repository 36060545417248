import { BACKEND_URL } from '../../../../../../config';

export const pathProduct = `${BACKEND_URL}/img/shop/shopProducts`;
export const pathPromotion = `${BACKEND_URL}/img/shop/promotions`;
export const pathCoupon = `${BACKEND_URL}/img/shop/coupons`;
export const pathSpecialpackage = `${BACKEND_URL}/img/shop/packages`;
export const pathAdvertisement = `${BACKEND_URL}/img/shop/advertisement`;
export const pathBroadcast = `${BACKEND_URL}/img/shop/broadcast`;
export const pathMemberGetMember = `${BACKEND_URL}/img/shop/mgm`;
export const pathFunsharing = `${BACKEND_URL}/img/shop/funsharing`;

