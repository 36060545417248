const menu = seqId => ({
  customer: [
    { id: 'menu.home', route: `/shop/${seqId}/home` },
    { id: 'MENU.SHOW_DEPOSIT', route: `/shop/${seqId}/products` },
    { id: 'menu.booking', route: `/shop/${seqId}/booking-management/new` },
    { id: 'menu.booking2', route: `/shop/${seqId}/booking2-createbooking` },
    {
      id: 'menu.history',
      route: '',
      submenu: [
        { id: 'menu.booking.history', route: `/shop/${seqId}/booking-management`, isSubMenu: true },
        { id: 'menu.booking2.history', route: `/shop/${seqId}/booking2-history/branch-all`, isSubMenu: true },
        { id: 'menu.order.history.new', route: `/shop/${seqId}/order-history`, isSubMenu: true },
        { id: 'menu.order.history.gas', route: `/shop/${seqId}/gas-order-history`, isSubMenu: true },
        // { id: 'MENU_REDEEM_HISTORY', route: `/shop/${seqId}/redeem-history`, isSubMenu: true },
        // {
        //   id: 'menu.expire.points',
        //   route: `/shop/${seqId}/expire-points`,
        //   isSubMenu: true
        // },

        // {
        //   id: 'menu.coupon.history',
        //   route: `/shop/${seqId}/coupon-history`,
        //   isSubMenu: true
        // },
        { id: 'menu.history', route: `/shop/${seqId}/history`, isSubMenu: true }
      ]
    },
    // { id: 'menu.history', route: `/shop/${seqId}/history` },
    { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact` }
  ],
  user: [
    { id: 'menu.home', route: `/shop/${seqId}/home` },
    { id: 'MENU.SHOW_DEPOSIT', route: `/shop/${seqId}/products` },
    { id: 'MENU_REDEEM_HISTORY', route: `/shop/${seqId}/redeem-history` },
    { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact` }
  ],
  'shop-owner': [
    { id: 'menu.home', route: `/shop/${seqId}/home` },
    { id: 'menu.dashboard', route: `/shop/${seqId}/admin/dashboard` },
    { id: 'MENU_ADMIN_MANAGE', route: `/shop/${seqId}/admin/adminManage` },
    {
      id: 'MENU_MEMBER',
      route: '',
      submenu: [
        { id: 'MENU_INVITE_FRIEND', route: `/shop/${seqId}/invite`, isSubMenu: true },
        { id: 'MENU_MANAGE_MEMBER_GROUP', route: `/shop/${seqId}/admin/edit-groups`, isSubMenu: true },
        { id: 'MENU_USER_MANAGE', route: `/shop/${seqId}/admin/customers`, isSubMenu: true },
        // { id: 'MENU_MEMBER_GET_MEMBER_MANAGEMENT', route: `/shop/${seqId}/admin/mgm`, isSubMenu: true },
        // { id: 'MENU_MEMBER_GET_MEMBER_HISTORY', route: `/shop/${seqId}/admin/mgm/history`, isSubMenu: true },
        // { id: 'MENU_MEMBER_GET_MEMBER_REPORT', route: `/shop/${seqId}/admin/mgm/report`, isSubMenu: true },
        { id: 'MENU_FUNSHARING', route: `/shop/${seqId}/admin/funsharing`, isSubMenu: true }
      ]
    },
    { id: 'menu.pos', route: `/shop/${seqId}/admin/pos` },
    {
      id: 'MENU_BRANCH_MANAGEMENT',
      route: `/shop/${seqId}/admin/branch-management`
    },
    {
      id: 'contact.management',
      route: `/shop/${seqId}/admin/contact-management`,
      submenu: [
        { id: 'board.template.setting', route: `/shop/${seqId}/admin/board/template`, isSubMenu: true },
        { id: 'contact.management', route: `/shop/${seqId}/admin/customer-contact`, isSubMenu: true }
      ]
    },

    {
      id: 'menu.advertisement',
      route: '',
      submenu: [
        { id: 'menu.advertisement.manage', route: `/shop/${seqId}/admin/advertisement`, isSubMenu: true },
        { id: 'menu.broadcast.manage', route: `/shop/${seqId}/admin/broadcast`, isSubMenu: true }
      ]
    },
    {
      //intl.formatMessage({id: "menu.shop.management"})
      id: 'menu.shop.management',
      route: '',
      submenu: [
        { id: 'menu.category', route: `/shop/${seqId}/admin/category`, isSubMenu: true },
        { id: 'menu.product.list', route: `/shop/${seqId}/admin/products`, isSubMenu: true },
        { id: 'menu.addOn.management', route: `/shop/${seqId}/admin/add-on/management`, isSubMenu: true },
        {
          id: 'menu.inventory',
          route: `/shop/${seqId}/admin/products/inventory`,
          isSubMenu: true
        },
        {
          id: 'menu.inventory.request.management',
          route: `/shop/${seqId}/admin/inventory-request-management`,
          isSubMenu: true
        },
        {
          id: 'menu.order.gas.create',
          route: `/shop/${seqId}/order-gas/create`,
          isSubMenu: true
        },
        {
          id: 'menu.inventory.history',
          route: `/shop/${seqId}/admin/inventory-history`,
          isSubMenu: true
        },
        { id: 'menu.payment.management', route: `/shop/${seqId}/admin/paymentManagement`, isSubMenu: true },
        //{ id: 'menu.weight.management', route: `/shop/${seqId}/admin/weight`, isSubMenu: true },
        { id: 'menu.shipment.management', route: `/shop/${seqId}/admin/shipment`, isSubMenu: true },
        {
          id: 'menu.shopping.cart.setting',
          route: `/shop/${seqId}/admin/shopping-cart-setting`,
          isSubMenu: true
        },
        { id: 'menu.admin.order.history', route: `/shop/${seqId}/admin/orderManagement`, isSubMenu: true }
      ]
    },
    {
      id: 'menu.booking.management',
      route: 'booking-management',
      submenu: [
        // { id: 'menu.booking', route: `/shop/${seqId}/booking-management/new`, isSubMenu: true },
        { id: 'menu.booking.management', route: `/shop/${seqId}/booking-management`, isSubMenu: true },
        { id: 'menu.booking.setting', route: `/shop/${seqId}/booking-management/setting`, isSubMenu: true },
        { id: 'menu.invite.customer.booking', route: `/shop/${seqId}/booking-management/invite`, isSubMenu: true }
      ]
    },
    {
      // id: 'menu.booking2.management',
      id: 'menu.booking2.management',
      route: 'booking2-management',
      submenu: [
        // { id: 'menu.booking', route: `/shop/${seqId}/booking-management/new`, isSubMenu: true },
        { id: 'menu.booking2.management', route: `/shop/${seqId}/booking2-management`, isSubMenu: true },
        { id: 'menu.booking2.setting', route: `/shop/${seqId}/booking2-setting`, isSubMenu: true },
        { id: 'menu.invite2.customer.booking', route: `/shop/${seqId}/booking2-invite`, isSubMenu: true }
      ]
    },
    {
      id: 'menu.granton.management',
      route: 'granton-management',
      submenu: [
        { id: 'menu.granton.list', route: `/shop/${seqId}/admin/granton-management/list`, isSubMenu: true },
        { id: 'menu.granton.action', route: `/shop/${seqId}/admin/granton-management/action`, isSubMenu: true },
        { id: 'menu.granton.history', route: `/shop/${seqId}/admin/granton-management/history`, isSubMenu: true },
        { id: 'menu.granton.setting', route: `/shop/${seqId}/admin/granton-management/setting`, isSubMenu: true }
      ]
    },
    {
      id: 'SHOP.PACKAGE.CREATE',
      route: `/shop/${seqId}/admin/packages`
    },
    { id: 'MENU_CREATE_PROMOTION', route: `/shop/${seqId}/admin/promotions` },
    { id: 'MENU_CREATE_COUPONS', route: `/shop/${seqId}/admin/coupons` },
    { id: 'menu.sales.management', route: `/shop/${seqId}/admin/salesperson` },
    { id: 'MENU_CREATE_POINT_QRCODE', route: `/shop/${seqId}/admin/qrcode` },
    { id: 'menu.add.point.by.customer', route: `/shop/${seqId}/admin/customer-addpoint` },
    { id: 'menu.add.point', route: `/shop/${seqId}/admin/addPoint` },
    { id: 'MENU_REDEEM_HISTORY', route: `/shop/${seqId}/redeem-history` },
    { id: 'MENU_REPORT', route: `/shop/${seqId}/admin/report` },
    {
      id: 'MENU_SETTING',
      route: 'shop-management',
      submenu: [
        { id: 'MENU_SHOP_SETTING', route: `/shop/${seqId}/admin/shop-setting`, isSubMenu: true },
        { id: 'menu.registration.setting', route: `/shop/${seqId}/admin/registration-perks`, isSubMenu: true },
        { id: 'menu.conversion.rate', route: `/shop/${seqId}/admin/conversion-rate`, isSubMenu: true },
        { id: 'menu.theme.setting', route: `/shop/${seqId}/admin/setting`, isSubMenu: true },
        { id: 'menu.shortcut.setting', route: `/shop/${seqId}/admin/setting-shortcut`, isSubMenu: true },
        { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact`, isSubMenu: true },
        { id: 'menu.sms.setting', route: `/shop/${seqId}/admin/sms-setting`, isSubMenu: true },
        { id: 'MENU_FILTER_MANAGEMENT', route: `/shop/${seqId}/admin/filter`, isSubMenu: true },
        { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact`, isSubMenu: true }
      ]
    }
  ],
  'shop-admin': [
    { id: 'menu.home', route: `/shop/${seqId}/home` },
    { id: 'menu.dashboard', route: `/shop/${seqId}/admin/dashboard` },
    {
      id: 'MENU_MEMBER',
      route: '',
      submenu: [
        { id: 'MENU_INVITE_FRIEND', route: `/shop/${seqId}/invite`, isSubMenu: true },
        { id: 'MENU_MANAGE_MEMBER_GROUP', route: `/shop/${seqId}/admin/edit-groups`, isSubMenu: true },
        { id: 'MENU_USER_MANAGE', route: `/shop/${seqId}/admin/customers`, isSubMenu: true },
        // { id: 'MENU_MEMBER_GET_MEMBER_MANAGEMENT', route: `/shop/${seqId}/admin/mgm`, isSubMenu: true },
        // { id: 'MENU_MEMBER_GET_MEMBER_HISTORY', route: `/shop/${seqId}/admin/mgm/history`, isSubMenu: true },
        // { id: 'MENU_MEMBER_GET_MEMBER_REPORT', route: `/shop/${seqId}/admin/mgm/report`, isSubMenu: true },
        { id: 'MENU_FUNSHARING', route: `/shop/${seqId}/admin/funsharing`, isSubMenu: true }
      ]
    },
    {
      id: 'MENU_BRANCH_MANAGEMENT',
      route: `/shop/${seqId}/admin/branch-management`
    },
    {
      id: 'contact.management',
      route: `/shop/${seqId}/admin/contact-management`,
      submenu: [{ id: 'contact.management', route: `/shop/${seqId}/admin/customer-contact`, isSubMenu: true }]
    },
    {
      id: 'menu.advertisement',
      route: '',
      submenu: [
        { id: 'menu.advertisement.manage', route: `/shop/${seqId}/admin/advertisement`, isSubMenu: true },
        { id: 'menu.broadcast.manage', route: `/shop/${seqId}/admin/broadcast`, isSubMenu: true }
      ]
    },
    {
      id: 'menu.shop.management',
      route: '',
      submenu: [
        { id: 'menu.category', route: `/shop/${seqId}/admin/category`, isSubMenu: true },
        { id: 'menu.product.list', route: `/shop/${seqId}/admin/products`, isSubMenu: true },
        {
          id: 'menu.inventory',
          route: `/shop/${seqId}/admin/products/inventory`,
          isSubMenu: true
        },
        {
          id: 'menu.inventory.request.management',
          route: `/shop/${seqId}/admin/inventory-request-management`,
          isSubMenu: true
        },
        {
          id: 'menu.order.gas.create',
          route: `/shop/${seqId}/order-gas/create`,
          isSubMenu: true
        },
        {
          id: 'menu.inventory.history',
          route: `/shop/${seqId}/admin/inventory-history`,
          isSubMenu: true
        },
        { id: 'menu.payment.management', route: `/shop/${seqId}/admin/paymentManagement`, isSubMenu: true },
        // { id: 'menu.weight.management', route: `/shop/${seqId}/admin/weight`, isSubMenu: true },
        { id: 'menu.shipment.management', route: `/shop/${seqId}/admin/shipment`, isSubMenu: true },
        {
          id: 'menu.shopping.cart.setting',
          route: `/shop/${seqId}/admin/shopping-cart-setting`,
          isSubMenu: true
        },
        { id: 'menu.order.management', route: `/shop/${seqId}/admin/orderManagement`, isSubMenu: true }
      ]
    },
    {
      id: 'menu.booking.management',
      route: 'booking-management',
      submenu: [
        { id: 'menu.booking.management', route: `/shop/${seqId}/booking-management`, isSubMenu: true },
        { id: 'menu.booking.setting', route: `/shop/${seqId}/booking-management/setting`, isSubMenu: true },
        { id: 'menu.invite.customer.booking', route: `/shop/${seqId}/booking-management/invite`, isSubMenu: true }
      ]
    },
    {
      id: 'menu.booking2.management',
      route: 'booking2-management',
      submenu: [
        // { id: 'menu.booking', route: `/shop/${seqId}/booking-management/new`, isSubMenu: true },
        { id: 'menu.booking2.management', route: `/shop/${seqId}/booking2-management`, isSubMenu: true },
        { id: 'menu.booking2.setting', route: `/shop/${seqId}/booking2-setting`, isSubMenu: true },
        { id: 'menu.invite2.customer.booking', route: `/shop/${seqId}/booking2-invite`, isSubMenu: true }
      ]
    },
    { id: 'SHOP.PACKAGE.CREATE', route: `/shop/${seqId}/admin/packages` },
    { id: 'MENU_CREATE_PROMOTION', route: `/shop/${seqId}/admin/promotions` },
    { id: 'MENU_CREATE_COUPONS', route: `/shop/${seqId}/admin/coupons` },
    { id: 'menu.sales.management', route: `/shop/${seqId}/admin/salesperson` },
    { id: 'MENU_CREATE_POINT_QRCODE', route: `/shop/${seqId}/admin/qrcode` },
    { id: 'menu.add.point.by.customer', route: `/shop/${seqId}/admin/customer-addpoint` },
    { id: 'menu.add.point', route: `/shop/${seqId}/admin/addPoint` },
    { id: 'MENU_REDEEM_HISTORY', route: `/shop/${seqId}/redeem-history` },
    { id: 'MENU_REPORT', route: `/shop/${seqId}/admin/report` },
    {
      id: 'MENU_SETTING',
      route: 'shop-management',
      submenu: [
        { id: 'MENU_SHOP_SETTING', route: `/shop/${seqId}/admin/shop-setting`, isSubMenu: true },
        { id: 'menu.registration.setting', route: `/shop/${seqId}/admin/registration-perks`, isSubMenu: true },
        { id: 'menu.conversion.rate', route: `/shop/${seqId}/admin/conversion-rate`, isSubMenu: true },
        { id: 'menu.theme.setting', route: `/shop/${seqId}/admin/setting`, isSubMenu: true },
        { id: 'menu.shortcut.setting', route: `/shop/${seqId}/admin/setting-shortcut`, isSubMenu: true },
        { id: 'menu.sms.setting', route: `/shop/${seqId}/admin/sms-setting`, isSubMenu: true },
        { id: 'MENU_FILTER_MANAGEMENT', route: `/shop/${seqId}/admin/filter`, isSubMenu: true },
        { id: 'menu.contact.us', route: `/shop/${seqId}/contact`, isSubMenu: true }
      ]
    }

    // { id: 'menu.tutorial', route: `/shop/${seqId}/tutorial` },
    // { id: 'MENU_ANNOUNCEMENT' }
  ],
  'shop-report': [
    { id: 'menu.home', route: `/shop/${seqId}/home` },
    { id: 'menu.dashboard', route: `/shop/${seqId}/admin/dashboard` },
    { id: 'MENU_REPORT', route: `/shop/${seqId}/admin/report` },
    { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact` }
    // { id: 'MENU_ANNOUNCEMENT' }
  ],
  admin: [
    { id: 'MENU.HOME', route: `/shop/${seqId}/home` },
    { id: 'MENU_CREATE_SHOP' },
    { id: 'MENU_CREATE_GROUP_NOTIFY' },
    { id: 'MENU_MAINTENANCE' },
    { id: 'MENU_SUPPORT' },
    { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact` }
  ],
  'shop-custom': [
    { id: 'menu.home', route: `/shop/${seqId}/home` },
    { id: 'menu.dashboard', route: `/shop/${seqId}/admin/dashboard` },
    {
      id: 'MENU_MEMBER',
      route: '',
      submenu: [
        { id: 'MENU_INVITE_FRIEND', route: `/shop/${seqId}/invite`, isSubMenu: true },
        { id: 'MENU_MANAGE_MEMBER_GROUP', route: `/shop/${seqId}/admin/edit-groups`, isSubMenu: true },
        { id: 'MENU_USER_MANAGE', route: `/shop/${seqId}/admin/customers`, isSubMenu: true }
      ]
    },
    {
      id: 'menu.advertisement',
      route: '',
      submenu: [
        { id: 'menu.advertisement.manage', route: `/shop/${seqId}/admin/advertisement`, isSubMenu: true },
        { id: 'menu.broadcast.manage', route: `/shop/${seqId}/admin/broadcast`, isSubMenu: true }
      ]
    },
    {
      id: 'contact.management',
      route: `/shop/${seqId}/admin/contact-management`,
      submenu: [{ id: 'contact.management', route: `/shop/${seqId}/admin/customer-contact`, isSubMenu: true }]
    },
    {
      id: 'menu.shop.management',
      route: '',
      submenu: [
        { id: 'menu.category', route: `/shop/${seqId}/admin/category`, isSubMenu: true },
        { id: 'menu.product.list', route: `/shop/${seqId}/admin/products`, isSubMenu: true },
        {
          id: 'menu.inventory',
          route: `/shop/${seqId}/admin/products/inventory`,
          isSubMenu: true
        },
        { id: 'menu.payment.management', route: `/shop/${seqId}/admin/paymentManagement`, isSubMenu: true },
        //{ id: 'menu.weight.management', route: `/shop/${seqId}/admin/weight`, isSubMenu: true },
        { id: 'menu.shipment.management', route: `/shop/${seqId}/admin/shipment`, isSubMenu: true },
        {
          id: 'menu.shopping.cart.setting',
          route: `/shop/${seqId}/admin/shopping-cart-setting`,
          isSubMenu: true
        },
        { id: 'menu.admin.order.history', route: `/shop/${seqId}/admin/orderManagement`, isSubMenu: true }
      ]
    },
    {
      id: 'SHOP.PACKAGE.CREATE',
      route: `/shop/${seqId}/admin/packages`
    },
    {
      id: 'menu.booking.management',
      route: 'booking-management',
      submenu: [
        // { id: 'menu.booking', route: `/shop/${seqId}/booking-management/new`, isSubMenu: true },
        { id: 'menu.booking.management', route: `/shop/${seqId}/booking-management`, isSubMenu: true },
        { id: 'menu.booking.setting', route: `/shop/${seqId}/booking-management/setting`, isSubMenu: true },
        { id: 'menu.invite.customer.booking', route: `/shop/${seqId}/booking-management/invite`, isSubMenu: true }
      ]
    },
    {
      id: 'menu.booking2.management',
      route: 'booking2-management',
      submenu: [
        // { id: 'menu.booking', route: `/shop/${seqId}/booking-management/new`, isSubMenu: true },
        { id: 'menu.booking2.management', route: `/shop/${seqId}/booking2-management`, isSubMenu: true },
        { id: 'menu.booking2.setting', route: `/shop/${seqId}/booking2-setting`, isSubMenu: true },
        { id: 'menu.invite2.customer.booking', route: `/shop/${seqId}/booking2-invite`, isSubMenu: true }
      ]
    },
    {
      id: 'menu.granton.management',
      route: 'granton-management',
      submenu: [
        { id: 'menu.granton.list', route: `/shop/${seqId}/admin/granton-management/list`, isSubMenu: true },
        { id: 'menu.granton.action', route: `/shop/${seqId}/admin/granton-management/action`, isSubMenu: true },
        { id: 'menu.granton.history', route: `/shop/${seqId}/admin/granton-management/history`, isSubMenu: true },
        { id: 'menu.granton.setting', route: `/shop/${seqId}/admin/granton-management/setting`, isSubMenu: true }
      ]
    },
    { id: 'MENU_ADMIN_MANAGE', route: `/shop/${seqId}/admin/adminManage` },
    { id: 'MENU_CREATE_PROMOTION', route: `/shop/${seqId}/admin/promotions` },
    { id: 'MENU_CREATE_COUPONS', route: `/shop/${seqId}/admin/coupons` },
    { id: 'menu.sales.management', route: `/shop/${seqId}/admin/salesperson` },
    { id: 'MENU_CREATE_POINT_QRCODE', route: `/shop/${seqId}/admin/qrcode` },
    { id: 'menu.add.point.by.customer', route: `/shop/${seqId}/admin/customer-addpoint` },
    { id: 'menu.add.point', route: `/shop/${seqId}/admin/addPoint` },
    { id: 'MENU_REDEEM_HISTORY', route: `/shop/${seqId}/redeem-history` },
    { id: 'MENU_REPORT', route: `/shop/${seqId}/admin/report` },
    {
      id: 'MENU_SETTING',
      route: 'shop-management',
      submenu: [
        { id: 'MENU_SHOP_SETTING', route: `/shop/${seqId}/admin/shop-setting`, isSubMenu: true },
        { id: 'menu.conversion.rate', route: `/shop/${seqId}/admin/conversion-rate`, isSubMenu: true },
        { id: 'menu.theme.setting', route: `/shop/${seqId}/admin/setting`, isSubMenu: true },
        { id: 'menu.shortcut.setting', route: `/shop/${seqId}/admin/setting-shortcut`, isSubMenu: true },
        { id: 'menu.lineOA.setting', route: `/shop/${seqId}/admin/setting-line-oa`, isSubMenu: true },
        { id: 'MENU_FILTER_MANAGEMENT', route: `/shop/${seqId}/admin/filter`, isSubMenu: true },
        { id: 'MENU.CONTACT_US', route: `/shop/${seqId}/contact`, isSubMenu: true }
      ]
    }
  ]
});

export default menu;
